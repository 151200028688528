import React from "react"
import ReactPlayer from "react-player"

export default function MediaPlayer(props) {
  const { id, onMediaPlay, onMediaPause, ...otherProps } = props
  return (
    <ReactPlayer
      id={id}
      onPlay={() => onMediaPlay(id)}
      onPause={() => onMediaPause(id)}
      {...otherProps}
    />
  )
}
