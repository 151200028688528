import React from "react"
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faSoundcloud,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import classNames from "classnames"

const navigation = {
  main: [
    {
      name: "Mentions légales",
      href: "/mentions-legales/",
      classNames: "",
      target: "_blank",
    },
    {
      name: "Paramètres des cookies",
      href: "javascript:void 0",
      // https://support.cookiehub.com/article/72-opening-the-cookie-settings-dialog
      // classes: "ch2-open-settings-btn", // Not working (why?), use javascript instead
      onClick: () => window.cookiehub?.openSettings(),
    },
  ],
  social: [
    {
      name: "Email",
      href: "mailto:contact@thierrygoldwaser.com",
      icon: props => <FontAwesomeIcon icon={faEnvelope} {...props} />,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/channel/UCNmQnQkOdVi7G9QjClp-RFA",
      icon: props => <FontAwesomeIcon icon={faYoutube} {...props} />,
    },
    {
      name: "SoundCloud",
      href: "https://soundcloud.com/thierrygoldwaser",
      icon: props => <FontAwesomeIcon icon={faSoundcloud} {...props} />,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/in/thierry-goldwaser/",
      icon: props => <FontAwesomeIcon icon={faLinkedin} {...props} />,
    },
  ],
}
const AnimatedButton = props => {
  return (
    <motion.div
      className="mx-5"
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.3 },
      }}
      whileTap={{ scale: 0.9 }}
    >
      {props.children}
    </motion.div>
  )
}

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map(item => {
            const { name, href, classes, ...extraProps } = item
            return (
              <div key={name} className="px-5 py-2">
                <a
                  href={href}
                  className={classNames(
                    "text-base text-primary-500 hover:text-primary-900",
                    classes
                  )}
                  aria-label={name}
                  {...extraProps}
                >
                  {name}
                </a>
              </div>
            )
          })}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          {navigation.social.map(item => (
            <AnimatedButton>
              <a
                key={item.name}
                href={item.href}
                className="text-primary-400 hover:text-primary-500"
                aria-label={item.name}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon size="3x" aria-hidden="true" />
              </a>
            </AnimatedButton>
          ))}
        </div>
        <p className="mt-8 text-center text-base text-primary-400">
          &copy; {new Date().getFullYear()} Thierry Goldwaser
        </p>
      </div>
    </footer>
  )
}

export default Footer
