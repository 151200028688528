import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import { motion, useAnimation } from "framer-motion"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Footer from "../components/footer"
import { useInView } from "react-intersection-observer"
import { BgImage } from "gbimage-bridge"
import MediaPlayer from "../components/mediaPlayer"
import Bio from "../components/bio.mdx"
import SEO from "../components/seo"
import Section from "../components/section"

const Hero = styled.div`
  position: relative;
`

const H1 = styled.h1`
  ${tw`uppercase text-4xl sm:text-6xl font-serif`}
`

const PhotoCredit = styled.div`
  ${tw`absolute tracking-tight`}
  bottom: 1rem;
  right: 10px;
`

const H2 = styled(motion.h2)`
  ${tw`my-3 text-secondary-700 text-left text-3xl font-serif`}
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: var(--color-secondary-700);
  }
`

const H2Variants = {
  visible: { opacity: 1, transition: { duration: 1, delay: 0.2 } },
  hidden: { opacity: 0 },
}

const H2Animated = props => {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <H2 ref={ref} animate={controls} initial="hidden" variants={H2Variants}>
      {props.children}
    </H2>
  )
}

const Overlay = styled.div`
  content: "";
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: absolute;
  opacity: 0.5;
`

const BioContainer = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`

const BioPhoto = styled.div`
  flex: 1 1 300px;
`

const BioText = styled.div`
  flex: 2 1 400px;
  ${tw`px-3`}
`

// Min screen width = 320px (iPhone 5)
const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  column-gap: 30px;
  row-gap: 30px;
`

const Home = () => {
  const data = useStaticQuery(
    graphql`
      {
        desktop: file(relativePath: { eq: "thierry-bw.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  // Set ImageData.
  const bgImage = getImage(data.desktop)

  const youTubeVideoIds = ["6gp5K1LGgsM", "Aa_hwd_80T0"]
  const youTubeId2ComponentId = ytId => `youtube-${ytId}`
  const SOUNDCLOUD_PLAYER_ID = "soundCloud-player"
  const mediaIds = [
    ...youTubeVideoIds.map(youTubeId2ComponentId),
    SOUNDCLOUD_PLAYER_ID,
  ]

  /**
   * The playing state (true or false) of all medias. The key is the media ID.
   */
  const [playingState, setPlayingState] = useState(
    mediaIds.reduce((acc, item) => ({ ...acc, [item]: false }), {})
  )

  const onMediaPlay = mediaId => {
    // Pause all media except the one just playing
    mediaIds.forEach(id =>
      setPlayingState(prevState => ({ ...prevState, [id]: id === mediaId }))
    )
  }

  const onMediaPause = mediaId => {
    // Update the state of the media just paused
    setPlayingState(prevState => ({ ...prevState, [mediaId]: false }))
  }

  return (
    <div className="font-sans">
      <SEO
        title="Accueil"
        lang="fr"
        description="Site du pianiste français Thierry Goldwaser"
      />

      {/* Better set full height on the whole div to avoid flashing at load time */}
      <Hero className="text-neutral-300 h-screen w-screen">
        <BgImage
          Tag="section"
          className="flex items-center h-screen w-screen"
          image={bgImage}
        >
          {/*<motion.div*/}
          {/*  className="mx-8 text-neutral-300 text-center tracking-widest overflow-hidden"*/}
          {/*  initial={{ y: "-100%" }}*/}
          {/*  animate={{ y: "0"
           }}*/}
          {/*  // If specifying a duration, type spring must be also specified*/}
          {/*  transition={{ type: "spring", duration: 1.5 }}*/}
          {/*>*/}
          {/*  <H1>Thierry Goldwaser</H1>*/}
          {/*  <div className="font-serif text-4xl">Pianiste</div>*/}
          {/*</motion.div>*/}

          {/* Setting overlay with ::after pseudo-element on BackgroundImage does not work because always comes on top */}
          <Overlay />
          {/* Specify z-index, otherwise until animation is over, the div gets under the overlay (why?) */}
          <div className="mx-8 text-center tracking-widest z-50">
            <motion.div
              className="overflow-hidden m-3"
              initial={{ height: "0", y: "100%" }}
              animate={{ height: "auto", y: "0" }}
              transition={{ type: "spring", delay: 0, duration: 0.5, mass: 2 }}
            >
              <H1>Thierry Goldwaser</H1>
            </motion.div>
            <motion.div
              className="overflow-hidden m-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0 }}
            >
              <div className="font-serif text-2xl sm:text-4xl">Pianiste</div>
            </motion.div>
          </div>
          <AnchorLink to="/#bio">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5, delay: 1.5 }}
              style={{
                position: "absolute",
                left: "50%", // Horizontal centering
                bottom: "50px",
                width: "100px",
                transform: "translateX(-50%)",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </motion.div>
          </AnchorLink>
          <PhotoCredit>Photo&nbsp;: Emanuele Bastoni</PhotoCredit>
        </BgImage>
      </Hero>
      <Section id="bio">
        <H2Animated>Bio</H2Animated>
        <BioContainer>
          <BioPhoto className="pb-3">
            {/* Padding bottom for small screens - Would be cleaner to use a grid + gutters */}
            {/* Embed Img inside div container so that it gets resized (and not cropped) when shrinked */}
            {/* Add margin: auto to Img if you want to have it centered horizontally when wrapped */}
            <StaticImage
              src="../images/thierry-21.jpg"
              alt="Thierry Goldwaser"
              placeholder="blurred"
              layout="constrained"
              style={{ borderRadius: 10 }}
            />
          </BioPhoto>
          <BioText>
            {/* MDX component, see https://www.gatsbyjs.com/docs/mdx/writing-pages/#importing-jsx-components-and-mdx-documents */}
            <Bio />
          </BioText>
        </BioContainer>
      </Section>
      <Section>
        <H2Animated>Vidéos</H2Animated>
        <VideoGrid className="my-5">
          {youTubeVideoIds.map(videoId => (
            <MediaPlayer
              id={youTubeId2ComponentId(videoId)}
              playing={playingState[youTubeId2ComponentId(videoId)]}
              url={`https://www.youtube-nocookie.com/embed/${videoId}`}
              onMediaPlay={onMediaPlay}
              onMediaPause={onMediaPause}
              width="100%"
              config={{
                youtube: {
                  playerVars: {
                    controls: 2,
                    rel: 0, // Show related videos from same channel
                  },
                },
              }}
            />
          ))}
        </VideoGrid>
      </Section>
      <Section>
        <H2Animated>Audio</H2Animated>
        <div className="flex flex-wrap justify-center my-5">
          <MediaPlayer
            id={SOUNDCLOUD_PLAYER_ID}
            playing={playingState[SOUNDCLOUD_PLAYER_ID]}
            url="https://soundcloud.com/thierrygoldwaser/sets/thierry-goldwaser-pianiste"
            onMediaPlay={onMediaPlay}
            onMediaPause={onMediaPause}
            width="100%"
            config={{
              soundcloud: {
                options: {
                  sharing: false,
                  download: false,
                  show_artwork: false,
                  show_user: false,
                  buying: false,

                  // The following options are undocumented in https://developers.soundcloud.com/docs/api/html5-widget#params
                  show_teaser: false, // When playing is paused, don't show the "Hear more" banner
                  visual: false, // Do not show the (quite ugly) background image
                },
              },
            }}
          />
        </div>
      </Section>
      <Footer />
    </div>
  )
}

export default Home
