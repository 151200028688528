import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Thierry Goldwaser débute le piano à l'âge de dix ans avec Louis-Claude Thirion mais choisit d'interrompre son cursus musical
à l’âge de 17 ans pour se consacrer à des études d’ingénieur, au terme desquelles il est diplômé de Supélec.
Il entame alors une carrière professionnelle dans l’informatique tout en complétant sa formation pianistique.
Au cours de celle-ci il reçoit notamment les conseils d’Aïda Mouradian, Marie-Paule Siruguet et Dominique Merlet.
Il étudie également l'harmonie avec Isabelle Duha. `}<br />{`
En 2005, Thierry obtient le premier prix du Concours International des Grands Amateurs de Piano, ainsi que le prix du public.
En 2010 il est également lauréat du concours international de piano amateur de Berlin, ce qui lui donne l’occasion de
jouer dans la prestigieuse salle de la Philharmonie de Berlin. `}<br /><br />{`
Thierry se produit régulièrement en récital, notamment dans le cadre du festival `}<a parentName="p" {...{
        "href": "https://www.lesamateursvirtuoses.com"
      }}>{`Les Amateurs Virtuoses`}</a>{` :
en France (Paris, Cannes, Nice), en Italie, aux États-Unis, en Finlande, au Brésil, en Hongrie, en Chine et en Afrique du Sud.
Lors de ces concerts, il a été amené à jouer avec la regrettée Brigitte Engerer, ainsi qu’en duo de pianos avec François-René Duchâble.`}<br />{`
Thierry s'est également produit en soliste avec orchestre à plusieurs reprises : deuxième concerto de Rachmaninov salle Gaveau, deuxième concerto de Saint-Saëns à Seattle,
Totentanz de Liszt à Berlin, et en 2018 la `}<em parentName="p">{`Rhapsody in Blue`}</em>{` de Gershwin avec l'orchestre Philharmonique du Cap en Afrique du Sud.`}</p>
    <br />
Parallèlement à ses activités musicales, Thierry Goldwaser travaille aujourd'hui en tant que développeur Web indépendant.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      